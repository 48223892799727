import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngxs/store";
import { filter } from "rxjs/operators";
import { translateAndFormat } from "src/app/i18next";
import { isDefined } from "src/utils/isDefined/isDefined";
import { AggregateModalData } from "../../../models/modal-data.models";
import { OpenDefectTableViewModel } from "../../../models/open-defect-table.models";
import { RepairModalComponent } from "../modal/repair-modal.component";
import { SetNumberOfDefects } from "../state/repairs.state";

export interface InputRepairObject {
	canAssignMechanic: boolean;
	defects: OpenDefectTableViewModel[];
	type: string;
}

export interface DataForModal {
	data: InputRepairObject;
	width: string;
}

@Component({
	selector: "app-repair-buttons",
	templateUrl: "./repair-buttons.component.html",
	styleUrls: ["./repair-buttons.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class RepairButtonsComponent {
	@Input() canAssignMechanic: boolean;
	@Input() defectSelected: boolean;
	@Input() disabledButtonsDuePermissions: boolean;
	@Input() selectedHasRejectedDefect: boolean;
	@Input() selection: OpenDefectTableViewModel[];

	@Output() updateState = new EventEmitter();

	public repairType = "";
	public outputModalData: AggregateModalData;
	public dataForModal: DataForModal;

	constructor(public dialog: MatDialog, public store: Store) {}

	public openRepairDialog(type: string) {
		this.repairType = type;
		this.store.dispatch(new SetNumberOfDefects(this.selection.length));

		// mat-dialogs require at least a data field in the modal object, which we fill with our repairType, our selected defects, and the bool for if they can assign another mechanic to the repair
		this.dataForModal = {
			width: "580px",
			data: {
				type: type,
				defects: this.selection,
				canAssignMechanic: this.canAssignMechanic,
			} as InputRepairObject,
		};

		// open the dialog with the modal component and our dataForModal
		const repairDialog = this.dialog.open(RepairModalComponent, this.dataForModal);

		// the data that returns from the modal is an observable that can be anything. Here, I return the modal form and the mechanic (if there)
		// the emitted data is the above info along with defect selection and the repairType to process state in other components
		repairDialog
			.afterClosed()
			.pipe(filter(isDefined))
			.subscribe(result => {
				this.outputModalData = {
					configId: result.configId,
					newData: result,
					oldData: this.selection,
					repairType: this.repairType,
				};

				this.updateState.emit(this.outputModalData);
			});
	}

	get disabledButton() {
		return this.disabledButtonsDuePermissions || !this.defectSelected;
	}

	rejectedDefectTooltipMessage() {
		return translateAndFormat(
			this.disabledButtonsDuePermissions
				? "you do not have the permission to update the repair status"
				: this.selectedHasRejectedDefect
				? "can't repair rejected defect"
				: "please select something to repair",
			"capitalize",
		);
	}
}
