<div
	class="repair-status-buttons"
	fxLayout="row"
	fxLayoutAlign="end center"
>
	<div
		[matTooltip]="rejectedDefectTooltipMessage()"
		[matTooltipDisabled]="!disabledButton"
	>
		<app-action-button
			id="pending"
			type="outlined"
			title="pending"
			(buttonClicked)="openRepairDialog('Pending')"
			[disabled]="disabledButton"
		></app-action-button>
	</div>

	<div
		[matTooltip]="rejectedDefectTooltipMessage()"
		[matTooltipDisabled]="!disabledButton"
	>
		<app-action-button
			id="ignored"
			type="outlined"
			title="repair not needed"
			(buttonClicked)="openRepairDialog('Repair not needed')"
			[disabled]="disabledButton"
		></app-action-button>
	</div>

	<div
		[matTooltip]="rejectedDefectTooltipMessage()"
		[matTooltipDisabled]="!disabledButton"
	>
		<app-action-button
			id="repaired"
			title="repaired"
			(buttonClicked)="openRepairDialog('Repaired')"
			[disabled]="disabledButton"
		></app-action-button>
	</div>
</div>
