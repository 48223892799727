import { environment } from "src/environments/environment";

/**
 * Get the UUID of View Inspections and Defects role based on the current environment.
 * @returns The UUID of the View Inspections role.
 */
export const getViewInspectionsRoleUUID = () => {
	switch (environment.environment) {
		case "development":
			return "703c7927-9366-412b-a619-b8277036c082";
		case "qa":
			return "533a9d3d-23f1-48c2-9141-99e6d09dbef4";
		case "production":
			return "e72221e5-b51f-4a0f-8209-e8cd508240be";
	}
};
